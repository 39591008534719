export type DataSetName = 'Assets';


export const dataSetFields = {
  Assets: [
    { name: 'Hostname', type: 'text', len: 15 },
    { name: 'IP', type: 'text', len: 21 },
    { name: 'Operating System', type: 'text', len: 25 },
    { name: 'Manufacturer', type: 'text', len: 11 },
    { name: 'Tags', type: 'text', len: 14 },
    { name: 'Agent Type', type: 'text', len: 14 },
  ],
};


export const dataUrl = {
  Assets: 'https://rajac.devinstance.mycybercns.com/api/asset/?skip=0&limit=100',
};
