import { Injectable } from "@angular/core";
import { Rdl as ARJS } from "@grapecity/activereports/core";
import { dataSetFields, dataUrl, DataSetName } from "./data";

function inches(val: number): string {
  return `${val}in`;
}


function points(val: number): string {
  return `${val}pt`;
}


function fieldVal(fieldName: string): string {
  return `=Fields!${fieldName}.Value`;
}

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor() {}
  /**
   * Creates a data source with data embedded into it
   */
  private async getDataSource(
    dataSetName: DataSetName
  ): Promise<ARJS.DataSource> {
    // use Web Api to retrieve the data from the demodata API
    const url = `${dataUrl[dataSetName]['dataSources']}${dataUrl[dataSetName]['dataSet']}`
    const data = await fetch(url).then((res) => res.json());
    const dataSource: ARJS.DataSource = {
      Name: "DataSource",
      ConnectionProperties: {
        DataProvider: "JSONEMBED",
        ConnectString: `jsondata=${JSON.stringify(data.value)}`,
      },
    };
    return dataSource;
  }

  /**
   * Creates a dataset that reads the data of the data source
   */
  private getDataSet(dataSetName: DataSetName): ARJS.DataSet {
    const dataSet: ARJS.DataSet = {
      Name: dataSetName,
      Query: {
        CommandText: "$.*",
        DataSourceName: "DataSource",
      }, // @ts-ignore
      Fields: dataSetFields[dataSetName].fields.map((f:any) => ({
        Name: f.name,
        DataField: f.name,
      })),
    };
    return dataSet;
  }

  private getFieldInfo(dataSetName: DataSetName, fieldName: string): any {// @ts-ignore
    return dataSetFields[dataSetName].fields.filter(
      (field: any) => field.name === fieldName
    )[0];
  }


  public async generateReport(
    dataSetName: any
  ): Promise<ARJS.Report> {
    // create Table Data region
    // see https://www.grapecity.com/activereportsjs/docs/ReportAuthorGuide/Report-Items/Data-Regions/Table/index
    const table: ARJS.Table = {
      Name: `Table_${dataSetName}`,
      DataSetName: dataSetName,
      Type: "table",// @ts-ignore
      TableColumns: dataSetFields[dataSetName].fields.map((f) => ({
        Width: inches(
          (7.5 * this.getFieldInfo(dataSetName, f.name).len) / 100
        ),
      })),
    };


    // create column headers for selected fields
    const columnHeadersRow: ARJS.TableRow = {
      Height: inches(0.5),
      TableCells: dataSetFields[dataSetName].fields.map((f) => ({
        Item: {
          Type: "textbox",
          Name: `textbox_header_${f.name}`,
          Value: `${f.name}`,
          CanGrow: true,
          Style: {
            BottomBorder: {
              Width: points(0.25),
              Style: "solid",
              Color: "Gainsboro",
            },
            Color: "#3da7a8",
            VerticalAlign: "middle",
            FontWeight: "bold",
            PaddingLeft: points(6),
            FontSize: points(10),
            TextAlign:
              this.getFieldInfo(dataSetName, f.name).type === "text"
                ? "left"
                : "right",
          },
        },
      })),
    };

    table.Header = {
      RepeatOnNewPage: true,
      TableRows: [columnHeadersRow],
    };

    // create table details that display for each row of the data
    table.Details = {
      SortExpressions: dataSetFields[dataSetName].sortBy
        ? [
            {
              Value: fieldVal(dataSetFields[dataSetName].sortBy),
              Direction: dataSetFields[dataSetName].isDescendingSorting
                ? "Descending"
                : "Ascending",
            },
          ]
        : [],
      TableRows: [
        {
          Height: inches(0.3),
          TableCells: dataSetFields[dataSetName].fields.map((f: any) => ({
            Item: {
              Type: "textbox",
              Name: `textbox_value_${f.col}`,
              Value: fieldVal(f.col),
              CanGrow: true,
              KeepTogether: true,
              Style: {
                VerticalAlign: "middle",
                PaddingLeft: points(6),
                Format:
                  this.getFieldInfo(dataSetName, f.name).type ===
                  "currency"
                    ? "c2"
                    : "",
                TextAlign:
                  this.getFieldInfo(dataSetName, f.name).type === "text"
                    ? "left"
                    : "right",
              },
            },
          })),
        },
      ],
    };
    // finally create a report with the table in the body and the report title in the page header
    const report: ARJS.Report = {
      DataSources: [await this.getDataSource(dataSetName)],
      DataSets: [this.getDataSet(dataSetName)],
      Page: {
        TopMargin: inches(0.5),
        BottomMargin: inches(0.5),
        LeftMargin: inches(0.5),
        RightMargin: inches(0.5),
        PageWidth: inches(8.5),
        PageHeight: inches(11),
      },
      Body: {
        ReportItems: [table],
      },
      PageHeader: {
        Height: inches(1),// @ts-ignore
        Name: "PageHeader",
        ReportItems: [
          {
            Type: "textbox",
            Name: "textbox_report_name",
            Value: dataSetName,
            Style: {
              FontSize: points(22),
              Color: "#3da7a8",
              VerticalAlign: "middle",
              TextAlign: "left",
            },
            Left: points(6),
            Top: points(0),
            Width: inches(7.5),
            Height: inches(1),
          },
        ],
      },
      Width: inches(7.5),
    };
    //console.log(JSON.stringify(report));
    return report;
  }
}

export interface ReportStructure {
  dataSetName: DataSetName;
  fields: string[];
  grouping: boolean;
  sortBy: string;
  isDescendingSorting: boolean;
  filterValues: string[];
}
